#target-url {
  width: calc(100% - 20ch);
  min-width: 20ch;
}

#http-request-header-authorization {
  width: 100%;
}

#generated-curl-command {
  width: 100%;
}

label,
span {
  font-size: small;
}

textarea {
  width: min(100%, 60ch);
  word-break: break-all;
}
