#target-url {
  width: calc(100% - 20ch);
  min-width: 20ch;
}

#http-request-header-authorization, #generated-curl-command {
  width: 100%;
}

label, span {
  font-size: small;
}

textarea {
  word-break: break-all;
  width: min(100%, 60ch);
}
/*# sourceMappingURL=index.d1455923.css.map */
